import { ITelemetryItem } from '@microsoft/applicationinsights-web';

export const telemetryInitializer = (envelope : ITelemetryItem): boolean => {
    const doNotTrackCodes = [0, 401, 404];
  
    envelope.data = envelope.data ? {...envelope.data, Tenant: process.env.VUE_APP_APPLICATION_INSIGHTS_TENANT_NAME} : {Tenant: process.env.VUE_APP_APPLICATION_INSIGHTS_TENANT_NAME };

    const baseData = envelope.baseData;

    const doNotTrackTicketsEndpoint = baseData && baseData.name.includes('/tickets/requester/') && envelope.baseType === "RemoteDependencyData" && baseData.responseCode === 406;

    if(doNotTrackTicketsEndpoint) {
      return false;
    }

    return !doNotTrackCodes.includes(envelope?.baseData?.responseCode)
  
  };