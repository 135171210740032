import { CustomerDto, UserDto } from '../../models/dtos/users/user.dtos';
import { AxiosResponse } from "axios";
import { BaseApiService } from "./base.api.service";
import { Observable } from 'rxjs';

export class UsersApiService extends BaseApiService {
    
    public getUserData(): Observable<AxiosResponse<UserDto>> {
        return super.get<UserDto>(`users`);
    }

    public getActiveCustomersForUser(userId: number): Observable<AxiosResponse<CustomerDto[]>> {
        return super.get(`users/${userId}/customers`);
    }
}