
import Vue from "vue";
import ProductGrid from "@/components/Products/ProductGrid.vue";
import Announcements from "@/components/Announcements/Announcements.vue"

export default Vue.extend({
  props: {
  },
  components: {
      Announcements,
      ProductGrid
  },
  data(): {

  } {
    return {
       
    };
  },
  methods: {
  },
  computed: {},
});
