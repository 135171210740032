
import { CustomerDto } from '@/models/dtos/users/user.dtos';
import { PropType } from 'vue';
import { debounce } from 'ts-debounce';
import Vue from 'vue';

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    }, 
    hostCustomer: {
      type: Object as PropType<CustomerDto>,
      required: true
    },
    customers: {
      type: Array as PropType<CustomerDto[]>,
      required: true
    }
  },
  data() {
    return {
      selectedCustomer: this.hostCustomer,
      searchText: "",
      filterText: ""
    };
  },
  computed: {
    resultQuery(): CustomerDto[] {
      if (this.filterText) {
        return this.customers
          .filter((item: CustomerDto) => {
            return item.customerName.toLowerCase()
              .includes(this.filterText.toLowerCase());
          });
      } else {
        return this.customers;
      }
    }
  },
  methods:{
    debounceInput: debounce(function(this: any){
      this.filterText = this.searchText
    }, 200),
    returnSelectedCustomer(customer: CustomerDto): void {
      this.$emit("changeCustomer", customer);
    },
    closeDialog(): void {
      this.$emit("close")
    }
  }
});
