import { VueConstructor } from 'vue';
import  VueAppInsights, { VueAppInsightsOptions } from '@/application-insights/app-insights.plugin';

export function initialize(Vue: VueConstructor<Vue>): void {

    const isDevelopment = process.env.NODE_ENV === "development";

    const options: VueAppInsightsOptions = {
      instrumentationKey: process.env.VUE_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY ?? '',
      appInsightsConfig: {
        customHeaders: [{
          "Tenant": process.env.VUE_APP_APPLICATION_INSIGHTS_TENANT_NAME
        }],
        disableExceptionTracking: isDevelopment,
        disableTelemetry: isDevelopment,
        enableAutoRouteTracking: !isDevelopment,
        enableUnhandledPromiseRejectionTracking: !isDevelopment
      }
    };

    Vue.use(VueAppInsights, options);
    
    // Track every error from vue
    Vue.config.errorHandler = (err, vm) => {

        if(!!vm.$appInsights && !isDevelopment){
          vm.$appInsights.trackException({
            exception: err,
            properties: {
              "Tenant": process.env.VUE_APP_APPLICATION_INSIGHTS_TENANT_NAME
            }
          });
        }
       
      }
      
    // Track every error on page
    window.onerror = (msg, url, line, col, error) => {
      if(!!Vue.prototype.$appInsights && !isDevelopment){
        Vue.prototype.$appInsights.trackException({
          exception: error,
          properties: {
            "Tenant": process.env.VUE_APP_APPLICATION_INSIGHTS_TENANT_NAME
          }
        });
      }
    }

}