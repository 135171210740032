<template>
    <Base> </Base>
</template>

<script>
import  Base  from './Base.vue';
export default {
    components: {
        Base
    }
}
</script>