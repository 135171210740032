
import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
    editMode:{
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
     
    }
  },
  methods: {
    customize(){
      this.$emit("edit-item-clicked")
    },
    save(){
      this.$emit("save-item-clicked");
    },
    cancel(){
      this.$emit("cancel-item-clicked");
    },
    reset(){
      this.$emit("reset-item-clicked");
    },
    createItem(){
      this.$emit("create-item-clicked")
    }
  }
})
