import { msalPluginInstance } from './../../plugins/msal-plugin';
import axios from 'axios';

export function jwtInterceptor(): void {
    axios.interceptors.request.use(async request => {
        const token = await msalPluginInstance.acquireToken();
        const isApiUrl = request.url?.startsWith(process.env.VUE_APP_BASE_APP_GATEWAY_API_URL!);

        if (token && isApiUrl) {
            request.headers.Authorization = `Bearer ${token}`;
        }

        return request;

    }, err => Promise.reject(err));
}