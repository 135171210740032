import { CustomErrors } from "./custom-errors";

export const FRESHSERVICE_ERRORS: CustomErrors = {
    'FRESH_SERVICE_RATE_LIMIT_EXCEEDED':
    {
        title: 'Freshservice rate limit exceeded',
        message: 'Freshservice rate limit exceeded.'
    },
    'FRESH_SERVICE_ERROR':
    {
        title: 'Freshservice error',
        message: 'Freshservice error.'
    },
    'FRESH_SERVICE_RESOURCE_NOT_FOUND':
    {
        title: 'Freshservice resource not found',
        message: 'Freshservice resource not found.'
    },
    'FRESH_SERVICE_UNAVAILABLE':
    {
        title: 'Freshservice unavailable',
        message: 'Freshservice unavailable.'
    },
    'FRESH_SERVICE_API_EMAIL_INVALID_VALUE':
    {
        title: 'Freshservice invalid email',
        message: 'Freshservice invalid email.'
    },
}

export const FreshServiceErrorKeys = {
    FRESH_SERVICE_API_EMAIL_INVALID_VALUE: "FRESH_SERVICE_API_EMAIL_INVALID_VALUE",
    FRESH_SERVICE_UNAVAILABLE: "FRESH_SERVICE_UNAVAILABLE",
    FRESH_SERVICE_RESOURCE_NOT_FOUND: "FRESH_SERVICE_RESOURCE_NOT_FOUND",
    FRESH_SERVICE_ERROR: "FRESH_SERVICE_ERROR",
    FRESH_SERVICE_RATE_LIMIT_EXCEEDED: "FRESH_SERVICE_RATE_LIMIT_EXCEEDED"
};
