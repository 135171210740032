import { BaseEnvidanPortalApiService } from "./base.envidanportal.api.services";
import axios from "axios";


export class EnvidanPortalApiService extends BaseEnvidanPortalApiService {
    // Logs out from EnviDan Portal by calling EnviWCFWebservice logout endpoint which signs the user out and deletes the envisecure cookie
    public async Logout() : Promise<void> {
        await axios
            .post(`${this.baseUrl}WebShellService.json/Logout`, null, this.config)
                // we catch all errors because we don't want the BaseApp logout flow to be interrupted by an error from the legacy monolith logout
                .catch(function (error) {
                    if (error.response) {
                      // The request was made and the server responded with a status code
                      // that falls out of the range of 2xx
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                      // http.ClientRequest in node.js
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }
                    console.log(error.config);
                  });
      }
}