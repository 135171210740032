import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/components/Home/Home.vue';
import UserProfile from '@/components/Profile/UserProfile.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/profile',
    name: 'profile',
    component: UserProfile
  },
  {
    path: "*",
    redirect: '/home'
  }
];

const router = new VueRouter({
  routes
});


export default router
