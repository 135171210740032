<template lang="html">
  <v-container class="profile-wrapper" fluid>
    <v-row cols=12 class="row-wrapper" v-if="!isLoading && userData">
      <v-col class="navbar-side" xsm="3" md="2" sm="3" v-if="showSidebar">
      <NavigationalSidebar
        class="sidebar"
        :sidebarItems="sidebarItems"
        :initialNavigationItem="sidebarItems[0]"
        @click="handleSidebarClick"
      >
      </NavigationalSidebar>
      </v-col>
      <v-col md="10" xsm="9" sm="9" pl="0" ml="0" class="avatar-form pl-0 ml-0 d-flex">
        <div class="avatar-wrapper d-flex" >
          <v-container class="d-flex avatar-container">
            <div class="left-avatar">
            <v-responsive aspect-ratio="1/1">
                <v-avatar tile class="user-avatar rounded"> 
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
            </v-responsive>
            </div>
            <div class="form-properties">
                <v-text-field dense class="text-input" :label="$t('user-profile-name-label')" outlined readonly disabled :value="userData.fullName"></v-text-field>
                <v-text-field dense class="text-input" :label="$t('user-profile-email')" outlined readonly disabled :value="email"></v-text-field>
                <v-text-field dense class="text-input" :label="$t('user-profile-phone')" outlined disabled readonly :value="userData.workPhoneNumber || ' '"></v-text-field>
                <v-select
                  disabled
                  :items="languageItems"
                  :label="$t('user-profile-language')"
                  item-text="name"
                  item-value="id"
                  readonly
                  dense
                  class="text-input language-dropdown"
                  v-model="selectedLanguage"
                  outlined
                ></v-select>
            <v-btn class="change-password-button" v-if="!isAdUser" @click="changePassword">{{ $t('user-profile-change-password-button')}}</v-btn>

            </div>
           </v-container>


        </div>
       </v-col>
    </v-row>

    <v-row v-else>
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate="indeterminate"></v-progress-circular>
        </v-col>
    </v-row>
    
  </v-container>
</template>
 
<script>
import Vue from "vue";
import { NavigationalSidebar } from "@envidan/shared-frontend-components";
import { UserLanguage } from '@/models/enums/language.enum'
import { Subject, takeUntil } from "rxjs";

export default Vue.extend({
  components: {
    NavigationalSidebar,
  },
  data() {
    return {
      sidebarItems: [
        {
          title: this.$t('user-profile-home'),
          iconName: "",
          selected:true
        },
      ],
      languageItems: Object.entries(UserLanguage).filter(([key, value]) => !!key.length && !!value.length).map(([key, value]) => {
        return {
          id: +key,
          name: value.toString()
        }
      }),
      selectedLanguage: UserLanguage.English,
      userData: null,
      isLoading: false,
      destroyed$: new Subject()
    };
  },
  methods: {
    handleSidebarClick(event) {
      console.log(event);
    },
    initializeData() {
      this.isLoading = true;
      this.$api.users.getUserData().pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: user => {
          this.userData = user.data;
          this.selectedLanguage = user.data.languageId;
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      })
    },
    async changePassword() {
      await this.$msal.passwordReset(this.email, this.$i18n.locale);
    }
  },
  computed: {
    email() {
     return this.$store.state.currentUser.email;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isAdUser() {
      return this.$store.state.currentUser.isADLogin;
    },
    showSidebar() {
      return false && this.$vuetify.breakpoint.mobile;
    }
  },
  mounted() {
    this.initializeData();
  },
  beforeDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
});
</script>
 
<style scoped lang="scss">
  .user-avatar {
    border: 1px solid currentColor !important;
    height:100% !important;
    width:100% !important;
    > i {
    font-size: 250px !important;

    }
  }

  .row .navbar-side.col-sm-3.col-md-2.col {
    height: 100% !important;
    padding: 0 !important;
  }

  ::v-deep .language-dropdown.v-select > .v-input__control > .v-input__slot {
    cursor: default !important;

    & > .v-select__slot > .v-input__append-inner > .v-input__icon {
      display: none !important;
    }

  }

  .avatar-container{
    padding-left: 20px !important;
  }

  .text-input{
    width:50%;
  }

  .avatar-form{
    height: 100%;
  }

  .avatar-wrapper {
    width:100%;
    height:262px;
     .container {
       height: 100%;
     }
  }

  .left-avatar {
    display: flex;
    width:262px;
    margin-right: 1em;
  }

  .form-properties{
    width: calc(100% - (364px + 1em));
  }
  .profile-wrapper {
    height:100%;
    padding-bottom: 0;
  }

  .sidebar {
    height:100%;
  }

  .row-wrapper{
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    .text-input{
      width: 100%;
    }

    .profile-wrapper{
      overflow: auto;
    }

    .left-avatar{
      margin-right: 0;
      width: 100%;
      margin-bottom:20px;
    }

    .form-properties{
      width:100%;
    }
    .avatar-wrapper{
      flex-direction: column;

      .container {
        flex-direction: column;
        height: unset;
     }
    }

    .change-password-button {
      width: 100%;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width:1200px){
    .text-input{
      width:60%;
    }

    .navbar-side{
      flex: 0 0 25%;
      max-width: 25%;
    }

    .avatar-form {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }

  @media only screen and (min-width: 1900px){
    .text-input{
      width:35%;
    }
  }

  @media only screen and (min-width: 2200px) {
    .navbar-side {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    

    .avatar-form {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .avatar-wrapper {

      & .container{
        margin:0;
      }
    }
  }
  
</style>