import { CurrentUserDto } from '@/models/current-user.dto';
import { GetProductsDto } from '@/models/dtos/product';
import { CustomerDto } from '@/models/dtos/users';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: new CurrentUserDto,
    isAuthenticated: false,
    activeCustomer: {},
    supportAdmin:"",
    administration: "",
    configureCustomers: "",
    showLegacyProductDialog: true
  },
  getters: {
    getAdministrativeOptions (state) {
      return {
        configureCustomersLink: state.configureCustomers !== "" ? state.configureCustomers : null,
        administrationLink: state.administration !== "" ? state.administration : null,
        supportAdminLink: state.supportAdmin !== "" ? state.supportAdmin : null
      }
    }
  },
  mutations: {
    setCurrentUser(state, user: CurrentUserDto) {
      state.currentUser = user;
    },
    setActiveCustomer(state, activeCustomer: CustomerDto) {
      state.activeCustomer = activeCustomer;
    },
    setAdministrativeOptions(state, options: GetProductsDto[]){
      state.supportAdmin="";
      state.administration = "";
      state.configureCustomers = "";
      
      options.forEach(product => {
        if(product.title==="SupportAdmin"){
          state.supportAdmin=product.url;
        }
        if (product.title === "Administration"){
          state.administration = product.url;
        } 
        if (product.title === "Vælg egne kunde") {
          state.configureCustomers = product.url;
        }
      });
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
});
