import * as msal from "@azure/msal-browser";
import { BrowserCacheLocation } from "@azure/msal-browser";
import Vue, { PluginObject, VueConstructor } from "vue";
import { Subject } from "rxjs";
import { StringDict } from "@azure/msal-common";
export interface MsalPluginOptions {
    clientId: string;
    loginAuthority: string;
    passwordAuthority: string;
    knownAuthority: string;
}

let msalInstance: msal.PublicClientApplication;

console.log(process.env);

const accessApiScope = `https://${process.env.VUE_APP_TENANT_ID}/${process.env.VUE_APP_ACCESS_AS_USER_SCOPE_ID}/access_as_user`;

export let msalPluginInstance: MsalPlugin;

export class MsalPlugin implements PluginObject<MsalPluginOptions> {

    private pluginOptions: MsalPluginOptions = {
        clientId: "",
        loginAuthority: "",
        passwordAuthority: "",
        knownAuthority: ""
    };

    public isAuthenticated = false;
    public authenticated$ = new Subject<boolean>();

    public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.pluginOptions = options;
        this.initialize(options);
        msalPluginInstance = this;
        vue.prototype.$msal = Vue.observable(msalPluginInstance);
    }

    private initialize(options: MsalPluginOptions) {
        const msalConfig: msal.Configuration = {
            auth: {
                clientId: options.clientId,
                authority: options.loginAuthority,
                knownAuthorities: [options.knownAuthority]
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage
            },
        
            system: {
                loggerOptions: {
                    loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case msal.LogLevel.Error:
                                console.error(message);
                                return;
                            case msal.LogLevel.Info:
                                console.info(message);
                                return;
                            case msal.LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case msal.LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    },
                    piiLoggingEnabled: false,
                    logLevel: msal.LogLevel.Verbose
                }
            }
        };
        msalInstance = new msal.PublicClientApplication(msalConfig);
        this.isAuthenticated = this.getIsAuthenticated();
    }

    public onRedirect() : Promise<msal.AuthenticationResult | null> {
        return msalInstance.handleRedirectPromise();
    }

    public async signIn(): Promise<void> {
        try {
            const loginRequest: msal.PopupRequest = {
                scopes: ["openid", "profile", "offline_access", accessApiScope],
            };
            return msalInstance.loginRedirect(loginRequest);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
                try {
                    const passwordResetResponse: msal.AuthenticationResult = await msalInstance.loginPopup({
                        scopes: ["openid", "profile", "offline_access", accessApiScope],
                        authority: this.pluginOptions.passwordAuthority
                    });
                     this.isAuthenticated = !!passwordResetResponse.account;
                     this.authenticated$.next(!!passwordResetResponse.account);
                } catch (passwordResetError) {
                    console.error(passwordResetError);
                }
            } else {
                this.authenticated$.next(false);
                this.isAuthenticated = false;
            }
        }
    }

    public async signOut(): Promise<void> {
        await msalInstance.logoutRedirect();
        this.authenticated$.next(false);
        this.isAuthenticated = false;
    }


    public async passwordReset(email?: string | undefined, language?: string | undefined): Promise<void> {
        let stringDict: StringDict | undefined;


        if(email?.length){
            stringDict = {
                hint: email
            }
        }

        if(language?.length){
            stringDict = stringDict ? {...stringDict, ui_locales: language } : { ui_locales: language };
        }

        console.log(stringDict);
        try {
            const resetRequest: msal.RedirectRequest = {
                scopes: ["openid", "profile", "offline_access", accessApiScope],
                authority: this.pluginOptions.passwordAuthority,
                extraQueryParameters: stringDict
            };
            this.authenticated$.next(false);
            this.isAuthenticated = false;

            return msalInstance.loginRedirect(resetRequest);
         }
         catch (err){
            console.log(err);
            return this.signIn();
         }
    }

    public getAllAccounts() {
        return msalInstance.getAllAccounts();
    }

    public async acquireToken() {
        const request = {
            account: msalInstance.getAllAccounts()[0],
            scopes: [accessApiScope]
        };
        try {
            
            const response = await msalInstance.acquireTokenSilent(request);
            console.log(response);

            this.authenticated$.next(true);

            return response.accessToken;            
        } catch (error) {
            if (error instanceof msal.InteractionRequiredAuthError) {
                try{
                    await msalInstance.acquireTokenRedirect(request);
                } catch {
                    await this.signIn();
                }
            }
            this.authenticated$.next(false);
            await this.signIn();

            return false;
        }
    }

    public getIsAuthenticated(): boolean {
        const accounts: msal.AccountInfo[] = msalInstance.getAllAccounts();

        this.authenticated$.next(accounts && accounts.length > 0);
        return accounts && accounts.length > 0;
    }

}
