import { BlobDeleteResponse, BlobServiceClient, BlockBlobUploadResponse } from "@azure/storage-blob";

export class ImageService {

  private withoutSlash = process.env.VUE_APP_BLOB_STORAGE_URL!.substring(0, process.env.VUE_APP_BLOB_STORAGE_URL!.length -1);
  private blobServiceClient = new BlobServiceClient(`${this.withoutSlash}${process.env.VUE_APP_AZURE_BLOB_STORAGE_SAS_TOKEN!}`);
  
  private imageContainerClient = this.blobServiceClient.getContainerClient("images");

  public async uploadBlobAsync(fileName: string, file: File):  Promise<BlockBlobUploadResponse>{
    
    const uploadImageResponse = await this.imageContainerClient.uploadBlockBlob(fileName, file, file.size);

    return uploadImageResponse.response;
  }

  public async deleteBlobAsync(blobName: string): Promise<BlobDeleteResponse> {

    const response = await this.imageContainerClient.deleteBlob(blobName);

    return response;
  }
} 