export enum UserLanguage {
    Danish = 1,
    Norwegian = 2,
    Swedish = 3,
    English = 4
}

export const languagesMap = new Map<UserLanguage, string>([
    [UserLanguage.Danish, 'da'],
    [UserLanguage.Norwegian, 'no'],
    [UserLanguage.Swedish, 'sv'],
    [UserLanguage.English, 'en']
]);