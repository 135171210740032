import VueRouter  from 'vue-router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { VueConstructor } from 'Vue/types/vue';
import { telemetryInitializer } from './app-insights.telemetry';

export class VueAppInsightsOptions{
 public instrumentationKey!: string;
 public appInsightsConfig?: any;
 public onAfterScriptLoaded?: (s: any) => any;
 public appInsights?: ApplicationInsights;
}


function install (Vue: VueConstructor<Vue>, options: VueAppInsightsOptions): void {

  const config = options.appInsightsConfig || {};
  config.instrumentationKey = config.instrumentationKey || options.instrumentationKey;
  
  if (options.appInsights) {
    Vue.appInsights = options.appInsights;
  } else {
    Vue.appInsights = new ApplicationInsights({ config });
    Vue.appInsights.loadAppInsights();
    Vue.appInsights.addTelemetryInitializer(telemetryInitializer);
    if (typeof(options.onAfterScriptLoaded) === 'function') {
      options.onAfterScriptLoaded(Vue.appInsights);
    }
  }

  Object.defineProperty(Vue.prototype, '$appInsights', {
    get: () => Vue.appInsights
  })

}

// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install);
}


export default install;