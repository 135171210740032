import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify'
import { MsalPlugin, MsalPluginOptions } from './plugins/msal-plugin';
import './assets/AssetPack/font/webfonts.css';
import './assets/AssetPack/EnviDanIconPack/style.css';
import './assets/css/main.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { CurrentUserDto } from './models/current-user.dto';
import { AuthenticationResult } from '@azure/msal-browser';
import { jwtInterceptor } from './utils/interceptors/interceptors';
import { $api, ApiServices } from './services/api';
import datjs from "dayjs"
import i18n from './plugins/translations-plugin';
import * as VueAppInsightsInitializer from '@/application-insights/app-insights.initializer';
import VueGtag from "vue-gtag"
import { ImageService } from './services/blob-storage/image.blob-storage.service';

Vue.config.productionTip = false

const options: MsalPluginOptions = {
  clientId: process.env.VUE_APP_MSAL_CLIENT_ID!,
  loginAuthority:  process.env.VUE_APP_MSAL_LOGIN_AUTHORITY!,
  passwordAuthority: process.env.VUE_APP_MSAL_PASSWORD_RESET_AUTHORITY!,
  knownAuthority: process.env.VUE_APP_MSAL_KNOWN_AUTHORITY!
};

const msalPlugin = new MsalPlugin();

declare module "vue/types/vue" {
  interface Vue {
      $msal: MsalPlugin;
      $api: ApiServices;
      $appInsights: ApplicationInsights;
      $imageService: ImageService;
  }

  interface VueConstructor {
    appInsights: ApplicationInsights;
  }
}

Vue.use(msalPlugin, options);
Vue.use(VueAxios, axios);
VueAppInsightsInitializer.initialize(Vue);

if ('production' === process.env.NODE_ENV) {
  Vue.use(VueGtag, {
    appName: "BaseApp",
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID} 
  }, router);
}

let lastResponse : AuthenticationResult | null;

msalPlugin
.onRedirect()
.then(async response => 
{
  console.log(response);
  lastResponse = response;
  if (msalPlugin.getIsAuthenticated()) {
    let claims;
    const allAccounts = msalPlugin.getAllAccounts();
    if (!response) {
      claims = allAccounts[0].idTokenClaims;    
    }
    else {
      claims = response.idTokenClaims;
    }
    const claimsRecord = claims as Record<string, any>;
    const currentUser: CurrentUserDto = new CurrentUserDto;
    currentUser.envidanPortalId = claimsRecord['userId'];
    currentUser.objectId = claimsRecord['sub'];
    currentUser.tenantId = claimsRecord['tid'];
    currentUser.hostCustomerId = claimsRecord['hostCustomerId'];
    currentUser.firstName = claimsRecord['given_name'];
    currentUser.lastName = claimsRecord['family_name'];
    currentUser.email = claimsRecord['preferred_username'];
    currentUser.isADLogin = !!claimsRecord['aadUPN'];



    store.commit('setCurrentUser', currentUser);
    msalPlugin.authenticated$.next(true);
    if(response && !response.fromCache){
      router.replace('/');
    }
  }
  else {
    await msalPlugin.signIn();
  }
}).catch(async err => {
  if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
    await msalPlugin.passwordReset('', i18n?.locale)
  }
  else {
    try{
      await msalPlugin.acquireToken();
    } catch {
      await msalPlugin.signIn();
    }

    if(msalPlugin.isAuthenticated && msalPlugin.getAllAccounts().length){
      msalPlugin.authenticated$.next(true);
      router.replace('/');
    }

    await msalPlugin.signIn();
  }
});

router.beforeEach((_to, _from, next) => {
  if (!msalPlugin.getIsAuthenticated()) {
    next(false);
  }
  else {
    next();
  }
});

jwtInterceptor();
Vue.prototype.$api = $api;
Vue.prototype.$imageService = new ImageService();

Vue.filter('truncate', function (text: string) {
  if (window.innerWidth < 1200 ) {
    return text.slice(0, 27) + (27 < text.length ? '...' : '')
  } else if (window.innerWidth > 1300 && window.innerWidth < 1400 ) {
    return text.slice(0, 40) + (40 < text.length ? '...' : '')
  }else if (window.innerWidth > 1400 && window.innerWidth < 2500){
    return text.slice(0, 45) + (45 < text.length ? '...' : '')
  } else if (window.innerWidth > 2500){
    return text.slice(0, 60) + (60 < text.length ? '...' : '')
  } else {
    return text.slice(0, 35) + (35 < text.length ? '...' : '')
  }
});

Vue.filter('stringOrSlash', function (text: string) {
  return text?.length ? text : '/';
});

Vue.filter('dateFormat', function (date: Date) {
  if (date) {
    return datjs(String(date)).format('DD/MMM/YYYY HH:mm')
  }
});

Vue.filter('ticketIdFormat', function (text: string) {
  return "#INC-" + text;
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
