
import Vue from "vue";
import Tickets from "@/components/Tickets/Tickets.vue";

export default Vue.extend({
  props: {},
  components: {
    Tickets,
  },
  data() {
    return {
      sheet: false as boolean
    };
  }
});
