export enum StrategyType {
    Default = 0,
    Legacy = 1,
    Webshell = 2,
    CustomerBased = 3,
    KeyzonesWebshell = 4
}

export enum ProductType {
    Application = 0,
    Custom = 1
}