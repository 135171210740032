

  import { CreateProductRequest } from "@/models/requests/product.request";
  import Vue, { PropType } from "vue";
  import { getExtension } from '@/utils/functions'
  import {v4 as uuidv4} from 'uuid';
  import { lastValueFrom } from "rxjs";
  import i18n from "@/plugins/translations-plugin";
  import { GetProductsDto } from "@/models/dtos/product";

  const acceptableExtensions = ['png', 'jpg', 'jpeg', 'bmp', 'jfif', 'pjpeg', 'pjp', 'bmp', 'dib' ];
  const defaultIcon = 'placeholder.png';

  export default Vue.extend({
    props: {
        show: {
          type: Boolean,
          required: true
        },
        edit: {
          type: Boolean,
          required: true
        },
        product: {
          type: Object as PropType<GetProductsDto>,
          required: false
        }
    },
    data: () => ({
      error: false,
      titleRules: [
        v => !!v || i18n.t('title-error-message')

      ],
      urlRules: [
        v => !!v || i18n.t('url-error-message')
      ],
      imageRules: [
        v => !v || (v.size > 0 && acceptableExtensions.includes(v.name.split(".")[1])) || i18n.t('file-format-error-message'),
        v => !v || v.size < 2000000 || i18n.t('file-size-error-message')
      ],
      currentId: 0 as number | string,
      currentTitle: '' as string,
      currentUrl: '' as string,
      currentImage: undefined as File | undefined,
      previewImage: process.env.VUE_APP_BLOB_STORAGE_URL + 'images/' + defaultIcon
    }),
    methods: {
      initializeData(){
        if(this.edit){
          this.currentId = this.product.productId;
          this.currentTitle = this.product?.title;
          this.currentUrl = this.product?.url;
          this.previewImage = process.env.VUE_APP_BLOB_STORAGE_URL + 'images/' + this.product.imageLink;
        }
      },
      dismiss() {
        this.$emit("dismiss");
        this.resetForm();
      },
      selectImage(image) {
        if (!!image){
          this.previewImage = URL.createObjectURL(image);
        } else {
          this.previewImage = process.env.VUE_APP_BLOB_STORAGE_URL + 'images/' + defaultIcon;
        }
      },
      async uploadImage(imageId: string, image: File){
          try {
            await this.$imageService.uploadBlobAsync(imageId + "." + getExtension(image.name), image);
            return true;
          } catch (e) {
            this.$appInsights.trackException({
              exception: e as Error
            });
            return false;
          }
         
      },
      async createLink() {
        let response = true;
        let imageId = uuidv4();
        if (!!this.currentImage) {
          response = await this.uploadImage(imageId, this.currentImage);
        }

        if (response){
          try {
             await lastValueFrom(this.$api.products.create({
              'userId': this.$store.state.currentUser.envidanPortalId,
              'customerId': this.$store.state.activeCustomer.customerId as number,
              "product":  {
                  "title": this.currentTitle,
                  "url": this.currentUrl,
                  "imageLink": !!this.currentImage ? imageId + "." + getExtension(this.currentImage.name) : defaultIcon
                }
              } as CreateProductRequest));
            return true;
          } catch {
            return false;
          }
        } else {
          return false;
        }
      },
      async editProduct(){
        let editedProduct = {}
        if (this.currentImage){

          const imageId = uuidv4();
          console.log(imageId)
          await this.uploadImage(imageId, this.currentImage);
           
          editedProduct = {
                  "title": this.currentTitle,
                  "url": this.currentUrl,
                  "image": imageId + "." + getExtension(this.currentImage.name)
                }
        } else {
          editedProduct = {
                  "title": this.currentTitle,
                  "url": this.currentUrl,
                  "image": null
                }
        }
        this.$emit('submit', editedProduct)
      },
      async submit(){   
        this.error = false;
        if((this.$refs.form as HTMLFormElement).validate()){
          if (this.edit) {
            await this.editProduct();
            this.resetForm();
          } else {
            let created = await this.createLink();
            if(created){
              this.$emit("submit");
              this.resetForm();
              this.$api.products.productsUpdated();
            } else {
              this.error = true;
            }
          }
        }
      },
      async deleteProduct(){
        this.$api.products.deleteProduct(this.$store.state.currentUser.envidanPortalId, this.product.productId)
        .subscribe({
           next: () => {
              if (this.product.imageLink !== defaultIcon){
                this.$imageService.deleteBlobAsync(this.product.imageLink);
              }
           }
        })
        this.dismiss();
        this.$api.products.productsUpdated();
      },
      resetForm(){
        (this.$refs.form as HTMLFormElement).reset();
        (this.$refs.form as HTMLFormElement).resetValidation();
        (this.$refs.titleInput as HTMLInputElement).blur();
        (this.$refs.urlInput as HTMLInputElement).blur();
        this.error = false;
      }
    },
    mounted(){
      this.initializeData();
    },
    computed: {
      getAcceptableFileTypes(){
        return acceptableExtensions.map(x=> 'image/' + x);
      }
    }
  })
