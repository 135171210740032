
import { TicketDto } from "@/models/dtos/ticket";
import { FRESHSERVICE_ERRORS, FreshServiceErrorKeys } from "@/models/errors"
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import Vue from "vue";
import TicketCard from "./TicketCard.vue"

export default Vue.extend({
  props: {},
  components: {
    TicketCard
  },
  data() {
    return {
      tickets: [] as TicketDto[],
      destroyed$: new Subject<boolean>() as Subject<boolean>,
      refreshTickets$: new BehaviorSubject<boolean>(true) as BehaviorSubject<boolean>,
      hasFinishedLoading: false as boolean,
      hasAccount: true as boolean,
      currentUserEmail: "" as string,
      hasError: false as boolean,
      errorMessage: "" as string
    };
  },
  mounted() {
    this.initializeData();
  },
  methods: {
  initializeData() {
      this.hasFinishedLoading = false;
      this.currentUserEmail = this.$store.state.currentUser.email
      this.$api.tickets.getTickets(this.currentUserEmail).pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: tickets => {
          this.tickets = tickets.data.value;  
          this.hasFinishedLoading= true;
        },
        error: err => {
          this.errorMessage = FRESHSERVICE_ERRORS[err.response.data.message].message;
          console.error(this.errorMessage);

          if(err.response.data.message == FreshServiceErrorKeys.FRESH_SERVICE_API_EMAIL_INVALID_VALUE){
            this.hasAccount = false;
          } 
          else{
            this.hasError = true;
          }
          this.hasFinishedLoading = true;
        },
        complete: () => {
          this.hasFinishedLoading = true;
        }
      })
    },
    goToFreshServiceSignUpPage() {
      window.open("https://support.envidan.com/support/signup", "_blank");
    },
    goToCreateTicketForm() {
      window.open("https://support.envidan.com/support/tickets/new", "_blank");
    },
    goToFreshServiceTickets(){
      window.open("https://support.envidan.com/support/tickets", "_blank");
    },
    refreshTickets(){
      this.initializeData();
      this.hasError = false;
    }
  },
  computed: {},
  beforeDestroy() {
      this.destroyed$.next(true);
      this.destroyed$.complete();

  }
});
