import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

function loadTranslations () {
    // this is temporary until we set up Azure CDN
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
        }
    });

    return messages;
}

const i18n = new VueI18n({
    // we can put the language in store in future so we set it here
    locale: undefined,
    messages: loadTranslations(),
});

export default i18n;