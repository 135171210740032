import { ProductsApiService } from './products.api.service';
import { TicketsApiService } from './tickets.api.service';
import { UsersApiService } from './users.api.service';
import { EnvidanPortalApiService } from './envidanportal.api.service';

export const $api : ApiServices = {
    users: new UsersApiService(),
    products: new ProductsApiService(),
    tickets: new TicketsApiService(),
    envidanPortal: new EnvidanPortalApiService()
}

export interface ApiServices {
    users: UsersApiService;
    products: ProductsApiService;
    tickets: TicketsApiService;
    envidanPortal: EnvidanPortalApiService;
}