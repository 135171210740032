import { GetProductsDto } from "@/models/dtos/product";

export class NewProductRequest{
    public title: string;
    public url: string;
    public imageLink: string;

    public constructor(title: string, url: string, imageLink: string){
        this.title = title;
        this.url = url;
        this.imageLink = imageLink;
    }
}

export class CreateProductRequest {
    public userId: number;
    public customerId: number;
    public product: NewProductRequest;

    public constructor(userId: number, customerId: number, product: NewProductRequest){
        this.userId = userId;
        this.customerId = customerId;
        this.product = product;
    }
}

export class UpdateProductsRequest {
    public userId: number;
    public customerId: number;
    public products: EditProductRequest[];

    public constructor(userId: number, customerId: number, products: EditProductRequest[]){
        this.userId = userId;
        this.customerId = customerId;
        this.products = products;
    }
}

export class EditProductRequest {
    public productId: string;
    public title: string;
    public url: string;
    public imageLink: string;
    public position: number;
    public isHidden = false;

    public constructor(productId: string, title: string, url: string, imageLink: string, position: number, isHidden: boolean){
        this.productId = productId;
        this.title = title;
        this.url = url;
        this.imageLink = imageLink;
        this.position = position;
        this.isHidden = isHidden;
    }

    public static fromDto(dto: GetProductsDto): EditProductRequest{
        return new EditProductRequest(
            dto.productId,
            dto.title,
            dto.url,
            dto.imageLink,
            dto.position,
            dto.isHidden
        )
    }
}