
import Vue from "vue";
import ProductTile from "@/components/Products/ProductTile.vue";
import Draggable from 'vuedraggable';
import { BehaviorSubject, combineLatest, filter, Subject, switchMap, takeUntil } from "rxjs";
import { GetProductsDto } from '@/models/dtos/product';
import { EditProductRequest, UpdateProductsRequest } from '@/models/requests/product.request';
import ProductForm from "@/components/Products/ProductForm.vue";
import ProductTopbar from "@/components/Products/ProductTopbar.vue";
import { ConfirmationDialog } from "@envidan/shared-frontend-components"

export default Vue.extend({
  props: {
  },
  components: {
    ProductTile,
    Draggable,
    ProductForm,
    ProductTopbar,
    ConfirmationDialog
  },
  data() {
    return {
        products: [] as GetProductsDto[],
        hasFinishedLoading: false as boolean ,
        customerChanged$: new BehaviorSubject<boolean>(!!this.$store.state.activeCustomer.customerId) as BehaviorSubject<boolean>,
        destroyed$: new Subject<boolean>() as Subject<boolean>,
        unsubscribeFromStore: null as (() => void) | null,
        drag: false as boolean,
        originalProductsArray: [] as GetProductsDto[],
        productsDictionaryByProductId: {} as { [key: string] : GetProductsDto },
        fab: false as boolean,
        isEditable: false as boolean,
        showProductForm: false as boolean,
        showConfirmationDialog: false as boolean

    };
  },
  mounted() {
      this.unsubscribeFromStore = this.$store.watch((state, getters) => state.activeCustomer.customerId, (customerId) => {
          if(customerId){
              this.customerChanged$.next(true);
          }
      })
     this.initializeData();
  },
  methods: {
      initializeData() {
          this.hasFinishedLoading = false;
          combineLatest([this.$api.products.refreshProducts$, this.customerChanged$]).pipe(
              filter(([rL, cust]) => !!cust),
              switchMap(() => {
                  this.hasFinishedLoading = false;
                  
                  return this.$api.products.getProducts(this.$store.state.activeCustomer.customerId);
              }),
              takeUntil(this.destroyed$))
              .subscribe({
                  next: products => {
                    this.products = products.data.value.products?.length ? products.data.value.products.map(x => {return {...x}}) : [];
                    console.log("Returned products", this.products);
                    this.productsDictionaryByProductId = this.products ?
                     this.products.reduce((r, o) => Object.assign(r, { [o.productId]: o }), {}) : {};
                    this.originalProductsArray = this.products.map(p => {return {...p}});
                    console.log("Copy of original products",this.originalProductsArray);
                    
                    this.$store.commit('setAdministrativeOptions', products.data.value.administrativeProducts);                  
                    this.hasFinishedLoading = true;
                  },
                  error: error => {
                    console.error(error);
                    this.hasFinishedLoading = true;
                  },
                  complete: () => {
                    this.hasFinishedLoading = true;
                  }
              });
      },
      updateProducts(){

        if(!this.$store.state.currentUser?.envidanPortalId || !this.$store.state.activeCustomer){
          return;
        }

        const mappedProducts = this.products.map((val, index) => { return { ...val,  position: index+1}});
        
        const originalProductsDict: { [key: string] : GetProductsDto }  =  this.originalProductsArray ?
                    this.originalProductsArray.reduce((r, o) => Object.assign(r, { [o.productId]: {...o} }), {}) : {};

        const differentProducts = mappedProducts.filter(x => {
          const originalProduct = originalProductsDict[x.productId];

          if(!originalProduct){
            return false;
          }
          return x.imageLink !== originalProduct.imageLink || x.url !== originalProduct.url || x.title !== originalProduct.title || x.position !== originalProduct.position || originalProduct.isHidden !== x.isHidden;
        });
        console.log("Changed products", differentProducts);
        if(differentProducts.length === 0){
          this.toggleEdit();
          return;
        }

        const request: UpdateProductsRequest = {
          userId: this.$store.state.currentUser.envidanPortalId,
          customerId: this.$store.state.activeCustomer.customerId,
          products: differentProducts.map(x => EditProductRequest.fromDto(x))
        };

        this.hasFinishedLoading = false;
        this.$api.products.updateProducts(request).subscribe({
          next: () => {
            this.originalProductsArray = [...mappedProducts];

            this.hasFinishedLoading = true;
          },
          error: err => {
            console.error(err);
            this.hasFinishedLoading = true;
          },
          complete: () => {
            this.hasFinishedLoading = true;
            this.$emit('save');
            this.toggleEdit();
            this.$api.products.productsUpdated();
          }
          });
      },
      resetPositions() {
        this.$emit('reset');
        const replacementProducts: GetProductsDto[] = [];
        let counter = 1;  
        console.log(this.originalProductsArray);    
        this.originalProductsArray.map(x => {return {...x}}).sort((a, b) => a.title.localeCompare(b.title)).forEach(val => {
          
          const elementFromArray = this.productsDictionaryByProductId[val.productId];
          if(elementFromArray){
            replacementProducts.push({
              ...elementFromArray, position : counter, isHidden : false
            })
            counter++;
          }
        });
        console.log(this.originalProductsArray);    
        this.products = replacementProducts;
      },
      toggleEdit(){
        this.isEditable = !this.isEditable;
      },
      toggleProductForm(){
        this.showProductForm = !this.showProductForm;
      },
      formSubmit(){
        this.toggleProductForm();
        this.$api.products.productsUpdated();
      },
      cancel(){
        if(this.changedProducts()){
          this.showConfirmationDialog = true;     
        } else {
          this.$emit('cancel');
          this.toggleEdit();
        }
      },
      confirmUnsaved(){
        this.showConfirmationDialog = false;
        this.$emit('cancel');
        this.toggleEdit();
        this.$api.products.productsUpdated();
      },
      changedProducts(){
        const mappedProducts = this.products.map((val, index) => { return { ...val, position: index+1}});
        
        console.log("Mapped products",mappedProducts);
        
        const originalProductsDict: { [key: string] : GetProductsDto }  =  this.originalProductsArray ?
                    this.originalProductsArray.reduce((r, o) => Object.assign(r, { [o.productId]: o }), {}) : {};

        console.log("Original product array",this.originalProductsArray);
        console.log("Original product dict",originalProductsDict);
        

        const differentProducts = mappedProducts.filter(x => {
          const originalProduct = originalProductsDict[x.productId];

          if(!originalProduct){
            return false;
          }
          console.log(originalProduct, x);
          
          return x.imageLink !== originalProduct.imageLink || x.url !== originalProduct.url || x.title !== originalProduct.title || x.position !== originalProduct.position || originalProduct.isHidden !== x.isHidden;
        });
        console.log(differentProducts)
        console.log(differentProducts.length)
        if(differentProducts.length !== 0){
          return true;
        }
        return false;
      },
      preventNav(event) {
        if (!this.changedProducts() && !this.isEditable) return
        event.preventDefault()
        event.returnValue = ""
    }
  },
  computed: { },
  beforeMount() {
    window.addEventListener("beforeunload", event => this.preventNav(event))
  },
  beforeDestroy() {
      if(this.unsubscribeFromStore){
          this.unsubscribeFromStore();
      }

      this.destroyed$.next(true);
      this.destroyed$.complete();

      window.removeEventListener('beforeunload', event => this.preventNav(event))
  }
});
