<template>
  <v-app>
    <topbar v-if="!isUserLoading" applicationTitle="Envidan" 
    :user="isLoadingCustomer ? {} : activeUser"
    :showUserProfileMenuItem="true"
    :customerSwitchButton="customerSwitchOptions"
    :logoClickHandler="routeToHome"
    @profile-item-clicked="routeToProfile"
    @switch-customer="changeCustomerDialogState" 
    @sign-out="signOutOidc"
    :administrativeOptions="$store.getters.getAdministrativeOptions"
    :profileMenuItemText="$t('user-dropdown-profile-item')"
    :switchCustomerMenuItemText="$t('user-dropdown-switch-customer-item')"
    :supportMenuItemText="$t('user-dropdown-support-item')"
    :signOutMenuItemText="$t('user-dropdown-log-out-item')"
    :adminDropdownText="$t('user-dropdown-admin-panel-item')"
    :administrationMenuItemText="$t('user-dropdown-administration-item')"
    :supportAdminMenuItemText="$t('user-dropdown-Support-Admin-item')"
    :configureCustomersMenuItemText="$t('user-dropdown-configure-customers-item')"
    :baseApp="true"
    >
      <template v-if="activeCustomer && user" v-slot:subtitle >
        {{ activeCustomer.customerName }}
      </template>
    </topbar>
    <select-customer-dialog
      :show="showCustomerDialog"
      :hostCustomer="this.activeCustomer"
      :customers="customers"
      @changeCustomer="setSelectedCustomer"
      @close="changeCustomerDialogState"
    ></select-customer-dialog>
    <v-main class="content-root">
      <router-view v-if="hasFinishedLoading"> </router-view>
        <v-col v-if="!hasFinishedLoading" class="d-flex justify-center">
            <v-progress-circular indeterminate="indeterminate"></v-progress-circular>
        </v-col>
    </v-main>
  </v-app>
</template>

<script>
import { Topbar } from "@envidan/shared-frontend-components";
import SelectCustomerDialog from "@/components/SelectCustomerDialog/SelectCustomerDialog.vue";
import { distinct, Subject, filter, switchMap, EMPTY, takeUntil } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { languagesMap } from '@/models/enums/language.enum'
import Vue from 'vue';
import VueCookies from 'vue-cookies'
import parser from 'tld-extract'
Vue.use(VueCookies);

const tooltipMessage = "user-dropdown-switch-customer-item-no-companies-available-for-switching-tooltip";

export default {
  components: {
    SelectCustomerDialog,
    Topbar,
  },
  data() {
    return {
      customers:[
      ],
      user: null,
      isLoadingCustomer: true,
      isUserLoading: true,
      showCustomerDialog: false,
      onDestroy$: new Subject(),
      customerSwitchOptions: {
        showButton: false,
        disabled: false,
        tooltip: null
      },
      administrativeOptions: {
        configureCustomersLink: this.$store.state.configureCustomers.length ? this.$store.state.configureCustomers : null,
        administrationLink: this.$store.state.administration.length ? this.$store.state.administration : null
      }
    }
  },
  mounted() {
    this.initializeData();
  },
  computed: {
      hasFinishedLoading () {
        return (!this.isUserLoading && !this.isLoadingCustomer)
      },
      isAuth: function() {
        return this.$msal.getIsAuthenticated();
      },
      activeUser: function() {
        if (this.$msal.getIsAuthenticated()) {
          return {
              id: 1,
              firstName: this.user ? this.user.fullName : '',
              lastName: '',
              email: this.$store.state.currentUser.email
          };
      }
      else {
        return null;
      }
    },
    activeCustomer() {
      return this.$store.state.activeCustomer ?? null;
    }
  },
  methods: {
    setCustomerSwitchOptions(showBtn, disableBtn, tooltipBtn) {
      this.customerSwitchOptions.showButton = showBtn;
      this.customerSwitchOptions.disabled = disableBtn;
      this.customerSwitchOptions.tooltip = this.$t(tooltipBtn);
    },
    setActiveCustomerInStore(customers, claimsCustomerId) {
      const currentStoreCustomerId = this.$store.state.activeCustomer.customerId;

      let currentActiveCustomer;

      if(!customers?.length){

         currentActiveCustomer = {
          uid: uuid(),
          customerId: claimsCustomerId,
          customerName: "Default"
        };
        
      } else {

        if (currentStoreCustomerId) {
          currentActiveCustomer = customers.find(x => x.customerId === currentStoreCustomerId) ?? customers.find(x => x.customerId === claimsCustomerId);
        } else {
          currentActiveCustomer = customers.find(x => x.customerId === claimsCustomerId);
        }

      }
            
      this.$store.commit('setActiveCustomer', currentActiveCustomer);
    },
    setSwitchCustomerBasedOnData(customers){

      if(!customers || customers.length <= 1){
        this.setCustomerSwitchOptions(false, true, tooltipMessage);
      } else {
        this.setCustomerSwitchOptions(true, false, null);
      }
    },
    routeToProfile() {
      if(!this.$route.path.includes('/profile')){
      this.$router.push('/profile');
      }
    },
    routeToHome() {
      if(!this.$route.path.includes('/home')){

        this.$router.push('/');
      }
    },
    initializeData() {
      this.isUserLoading = true;
      this.isLoadingCustomer = true;

      this.$msal.authenticated$.pipe(distinct(), filter(x => x), 
        switchMap(() => {
          return this.$api.users.getUserData()
        }),
        switchMap(user => {
          this.user = user.data;
          this.setUserLanguage(this.user.languageId);
          this.isUserLoading = false;
          const currentUserData = this.$msal.getAllAccounts()[0]?.idTokenClaims?.hostCustomerId;

          if(user.data && currentUserData){
            return this.$api.users.getActiveCustomersForUser(user.data.userId);
          }

          return EMPTY;
        }),
        takeUntil(this.onDestroy$)
        ).subscribe({
          next: customers => {
            
            const currentUserData = this.$msal.getAllAccounts()[0]?.idTokenClaims?.hostCustomerId;

            this.customers = customers.data;
            this.setActiveCustomerInStore(customers.data, currentUserData);
            this.setSwitchCustomerBasedOnData(customers.data);
            this.isUserLoading = false;
            this.isLoadingCustomer = false;
          },
          error: error => {
            this.setCustomerSwitchOptions(false, false, null);
            
            this.$store.commit('setActiveCustomer', {});
            console.error(error);
            this.isUserLoading = false;
            this.isLoadingCustomer = false;
          },
          complete: () => {
            this.isUserLoading = false;
            this.isLoadingCustomer = false;
          }
        });
    },
    async signOutOidc() {
      this.$store.commit('setActiveCustomer', {});
      this.$cookies.remove(process.env.VUE_APP_ENVIDAN_B2C_COOKIE_NAME, null, window.location.host.indexOf("localhost") === -1 ? parser(window.location.href).domain : undefined);
      await this.$api.envidanPortal.Logout();
      await this.$msal.signOut();
    },
    changeCustomerDialogState() {
      this.showCustomerDialog = !this.showCustomerDialog;
    },
    setSelectedCustomer(value) {
      this.$store.commit('setActiveCustomer', value);
      this.changeCustomerDialogState();
    },
    setUserLanguage(languageId) {
      const language = languagesMap.get(languageId);
      this.$i18n.locale = language;
    }
  },
  beforeDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
</script>
<style scoped>
.content-root {
  background-color: #f7f9fa;
  margin-top: 1.5px;
}
.row{
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0 !important
}
</style>