
import Vue from "vue";
import { TicketDisplayStatus, TicketDto } from "@/models/dtos/ticket"
import { PropType } from "vue";

export default Vue.extend({
  props: {
    ticket: {
      type: Object as PropType<TicketDto>,
      required: true
    }
  },
  components: {
  },
  data(){
    return {
    };
  }, 
  methods: { 
    openTicketOnFreshservice(){
      window.open(this.$props.ticket.ticketUrl, "blank_");
    }
  },
  computed: {
    getTicketStatus() {
      return TicketDisplayStatus.get(this.ticket.status);
    }
  }
});
