import { ProductsResponse } from '../../models/responses/Product.response';
import { AxiosResponse } from "axios";
import { BaseApiService } from "./base.api.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { Result } from '@/models/interfaces/result.interface';
import { CreateProductRequest, UpdateProductsRequest } from '@/models/requests/product.request';

export class ProductsApiService extends BaseApiService {
    public refreshProducts$ = new BehaviorSubject<boolean>(true);

    public getProducts(customerId: number): Observable<AxiosResponse<Result<ProductsResponse>>> {
        return super.get<Result<ProductsResponse>>(`product/${customerId}`);
    }

    public create(request: CreateProductRequest): Observable<AxiosResponse<Result<void>>> {
        return super.post<Result<void>>('product', request);
    }

    public updateProducts(request: UpdateProductsRequest): Observable<AxiosResponse<Result<void>>> {
        return super.patch<Result<void>>(`product`, request);
    }

    public deleteProduct(userId: number, productId: string): Observable<AxiosResponse<Result<void>>> {
        return super.delete<Result<void>>(`product/${productId}/user/${userId}`);
    }

    public productsUpdated(): void {
        this.refreshProducts$.next(true);
    }
}