
import ProductForm from "@/components/Products/ProductForm.vue"
import { PropType } from "vue";
import Vue from 'vue';
import VueCookies from 'vue-cookies'
import parser from 'tld-extract'
import { Subject } from 'rxjs';
import { EditProductDto, GetProductsDto, ProductType, StrategyType } from '@/models/dtos/product';
import { msalPluginInstance } from './../../plugins/msal-plugin';
Vue.use(VueCookies, { expire: '5min'});

export default Vue.extend({
  components: {
    ProductForm
  },
  props: {
    product: {
      type: Object as PropType<GetProductsDto>,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showProductForm: false,
      isCustom: this.$props.product.productType === ProductType.Custom ? true : false,
      isHidden: this.$props.product.isHidden,
      destroying$: new Subject(),
      oldValues:{
        isHidden: this.$props.product.isHidden,
        title: this.$props.product.title,
        url: this.$props.product.url,
        image: this.$props.product.imageLink
      }
    }
  },
  methods: {
    initializeData(){
      this.oldValues.title = this.$props.product.title;
      this.oldValues.url = this.$props.product.url;
      this.oldValues.image = this.$props.product.imageLink;
      this.oldValues.isHidden = this.$props.product.isHidden;
    },
    goToProduct(): void {
      if (this.isCustom) {
        window.open(this.$props.product.url, "_blank");
      } else {
        this.redirectToProductUrl();
      }
    },
    redirectToProductUrl(): void {
      msalPluginInstance.acquireToken().then(f=> {
        this.$cookies.set(process.env.VUE_APP_ENVIDAN_B2C_COOKIE_NAME!, f, undefined, undefined, window.location.host.indexOf("localhost") === -1 ? parser(window.location.href).domain : undefined);
        window.open(this.$props.product.url, "_self");
      });
    },
    getImgUrl() {
      return process.env.VUE_APP_BLOB_STORAGE_URL + 'images/' + this.$props.product.imageLink;
    },
    toggleShowHide(){
      this.isHidden = !this.isHidden;
      this.$props.product.isHidden = this.isHidden;
    },
    toggleProductForm(){
      this.showProductForm = !this.showProductForm;
    },
    click(){
      !this.isCustom ? this.toggleShowHide() : this.toggleProductForm();
    },
    formSubmit(product: EditProductDto){
      this.$props.product.title = product.title;
      this.$props.product.url = product.url;
      if (product.image){
        this.$props.product.imageLink = product.image;
      }
      this.showProductForm=false;
    },
    async save(){
      if (this.product.imageLink !== this.oldValues.image && this.oldValues.image !== 'placeholder.png') {
        await this.$imageService.deleteBlobAsync(this.oldValues.image);
      }
    },
    async cancel(){
      if (this.$props.product.imageLink !== this.oldValues.image && this.$props.product.imageLink !== 'placeholder.png'){
        await this.$imageService.deleteBlobAsync(this.$props.product.imageLink);
        this.$props.product.imageLink = this.oldValues.image;
      }
    },
    reset(){
      this.isHidden = false;
    }
  },
  mounted(){
    this.initializeData();
  },
  async created() {
    this.$parent?.$parent?.$on('save', await this.save) || '';
    this.$parent?.$parent?.$on('cancel', await this.cancel);
    this.$parent?.$parent?.$on('reset', await this.reset);
  },
  async beforeDestroy() {
    this.$parent?.$parent?.$off('save', await this.save);
    this.$parent?.$parent?.$off('cancel', await this.cancel);
    this.$parent?.$parent?.$off('reset', await this.reset);
  }
})
