import { TicketDto } from '../../models/dtos/ticket';
import { AxiosResponse } from "axios";
import { BaseApiService } from "./base.api.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { Result } from '@/models/interfaces/result.interface';

export class TicketsApiService extends BaseApiService {

    public refreshTickets$ = new BehaviorSubject<boolean>(true);

    public getTickets(email: string): Observable<AxiosResponse<Result<TicketDto[]>>> {
        return super.get<Result<TicketDto[]>>(`tickets/requester/${email}`);
    }

    public ticketsUpdated(): void {
        this.refreshTickets$.next(true);
    }
}