export enum TicketStatus {
    Open = 2,
    Pending = 3,
    Resolved = 4,
    Closed = 5,
    Planned = 6,
    Transferred = 7,
    WorkInProgress = 9,
    UAT = 10,
    ProductBacklog = 11,
    WIPSupport = 12
}

const ticketStatusOpen = 'ticket-status-open';
const ticketStatusPending = 'ticket-status-pending';
const ticketStatusTransferred = 'ticket-status-transferred';
const ticketStatusPlanned = 'ticket-status-planned';
const ticketStatusWorkInProgress = 'ticket-status-workinprogress';
const ticketStatusUAT = 'ticket-status-uat';
const ticketStatusResolved = 'ticket-status-resolved';
const ticketStatusClosed = 'ticket-status-closed';
const ticketStatusProductBacklog = 'ticket-status-product-backlog';
const ticketStatusWIPSupport = 'ticket-status-wip-support';


export const TicketDisplayStatus = new Map<TicketStatus, string>([
    [TicketStatus.Open, ticketStatusOpen],
    [TicketStatus.Pending, ticketStatusPending],
    [TicketStatus.Transferred, ticketStatusTransferred],
    [TicketStatus.Planned, ticketStatusPlanned],
    [TicketStatus.WorkInProgress, ticketStatusWorkInProgress],
    [TicketStatus.UAT, ticketStatusUAT],
    [TicketStatus.Resolved, ticketStatusResolved],
    [TicketStatus.Closed, ticketStatusClosed],
    [TicketStatus.ProductBacklog, ticketStatusProductBacklog],
    [TicketStatus.WIPSupport, ticketStatusWIPSupport]

])