import { render, staticRenderFns } from "./ProductTopbar.vue?vue&type=template&id=1a6c7add&"
import script from "./ProductTopbar.vue?vue&type=script&lang=ts&"
export * from "./ProductTopbar.vue?vue&type=script&lang=ts&"
import style0 from "./ProductTopbar.vue?vue&type=style&index=0&id=1a6c7add&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports