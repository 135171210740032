import axios, { AxiosResponse } from "axios";
import { from, Observable } from "rxjs";
export class BaseApiService {
    private baseUrl = `${process.env.VUE_APP_BASE_APP_GATEWAY_API_URL}/api/`;
    private config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

    public get<T>(url: string): Observable<AxiosResponse<T>> {
        return from(axios.get<T>(`${this.baseUrl}${url}`));
    }

    public post<T>(url: string, data: Record<string, any> = { }): Observable<AxiosResponse<T>> {
        return from(axios.post<T>(`${this.baseUrl}${url}`, JSON.stringify(data), this.config));
      }
    
    public put<T>(url: string, data: Record<string, any> = { }): Observable<AxiosResponse<T>> {
        return from(axios.put<T>(`${this.baseUrl}${url}`, JSON.stringify(data), this.config));
    }

    public delete<T>(url: string): Observable<AxiosResponse<T>> {
        return from(axios.delete<T>(`${this.baseUrl}${url}`));
    }

    public patch<T>(url: string, data: Record<string, any> = { }): Observable<AxiosResponse<T>> {
        return from(axios.patch<T>(`${this.baseUrl}${url}`, JSON.stringify(data), this.config));
    }
}